import React, { } from 'react'

const Footer = props => {
  return (
    <div>
      <footer className="main-footer">
        <strong>Trường Đại học Công nghệ, ĐHQGHN, 2021-2030</strong>. All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.1
        </div>
      </footer>          
    </div>

  )
}
export default Footer;
